















































/* eslint-disable camelcase */
import Vue from 'vue';
import { computed, defineComponent, reactive, Ref, ref, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { IMunicipality, IOperator, IRouteType, ITransporter } from '../../types';
import { PASSENGER_TYPE_GROUP } from '../../constants/RouteTypeGroups';
import { PROJECT_TITLE } from '@/constants/Global';

const routeTypeHelpers = createNamespacedHelpers('route-type');
const municipalitiesHelpers = createNamespacedHelpers('municipalities');
const transporterHelpers = createNamespacedHelpers('transporter');

export default defineComponent({
  props: ['routeGroupId'],
  setup(props) {
    const { isCurrentGeneral, current, menuMunicipalities }: {
      isCurrentGeneral: Ref<boolean>,
      current: Ref<IMunicipality>,
      menuMunicipalities: Ref<Array<IMunicipality>>,
    } = municipalitiesHelpers.useGetters(['isCurrentGeneral', 'current', 'menuMunicipalities']) as any;

    const routeTypeName = ref('');
    const transporters: Ref<Array<ITransporter>> = ref(reactive([]));
    const canPrint = ref(false);
    const isOneMunicipality = ref(false);
    const isPPTransport = ref(false);

    const totalTransports = computed(() => {
      let total = transporters.value.reduce((a, c) => {
        a.totalTS += c.count_ts ? c.count_ts : 0;
        return a;
      }, {
        totalTS: 0,
      });
      return total;
    });

    const { loadRouteTypes }: {
      loadRouteTypes: ({ all }: { all: boolean }) => Promise<Array<IRouteType>>,
    } = routeTypeHelpers.useActions(['loadRouteTypes']) as any;
    const { loadTransporters }: {
      loadTransporters: ({
        all,
        filter,
      }: {
        all: boolean,
        filter: {
          routeTypes?: Array<string>;
          typeGroup?: number;
          municipality?: number;
        },
        include: Array<string>,
        append: Array<string>,
        sort?: string,
      }) => Promise<{
        transporters: Array<ITransporter>,
      }>,
    } = transporterHelpers.useActions(['loadTransporters']) as any;

    const printReport = () => {
      let buttonsVisibility = document.querySelector('.transporters-table .action-wrapper') as HTMLButtonElement;
      let header = document.querySelector('header.header') as HTMLElement;
      let content = document.querySelector('.content') as HTMLElement;
      let table = document.querySelector('.transporters-table .table-transporter') as HTMLElement;
      let sticky: NodeListOf<HTMLElement> = document.querySelectorAll('.kommun-transportation .table-transporter table thead th');

      buttonsVisibility.hidden = true;
      header.hidden = true;
      content.style.margin = '0';
      table.style.overflow = 'visible';
      for (let el = 0; el < sticky.length; el++) {
        sticky[el].style.position = 'static';
      }
      document.title = `Организации`;

      window.print();

      document.title = PROJECT_TITLE;

      buttonsVisibility.hidden = false;
      header.hidden = false;
      content.style.margin = '';
      table.style.overflow = '';
      for (let el = 0; el < sticky.length; el++) {
        sticky[el].style.position = '';
      }
    };

    const loadData = () => {
      isPPTransport.value = Number(props.routeGroupId) === PASSENGER_TYPE_GROUP;
      loadRouteTypes({ all: true })
        .then(data => {
          const routeTypesIds: Array<string> = [];

          const filter: {
            routeTypes?: Array<string>,
            typeGroup?: number;
            municipality?: number,
          } = {
            typeGroup: Number(props.routeGroupId),
          };

          if (!isCurrentGeneral.value) {
            filter.municipality = current.value.id;
          }

          routeTypeName.value = routeTypesIds
            .map(routeTypeId => {
              const index = data.findIndex(routeType => routeType.id === +routeTypeId);
              if (index !== -1) {
                return data[index].name;
              } else {
                return '';
              }
            })
            .filter(name => name.length)
            .join(', ');

          const include = ['operators'];
          const append = ['count_ts'];
          const sort = 'name';

          return loadTransporters({ all: true, filter: filter, include: include, append: append, sort });
        })
        .then(data => {
          let maxCountMunicipalities = 1;
          transporters.value = data.transporters.map(transporter => {
            transporter.groups = [];
            if (Object.keys(transporter.groups).length > maxCountMunicipalities) {
              maxCountMunicipalities = Object.keys(transporter.groups).length;
            }
            isOneMunicipality.value = Object.keys(transporter.groups).length === 1;
            transporter.rowspan = 1;
            return transporter;
          });
          isOneMunicipality.value = maxCountMunicipalities === 1;
          canPrint.value = true;
        })
        .catch(() => {
          canPrint.value = false;
        });
    };
    loadData();

    watch(current, () => loadData());
    watch(() => props.routeGroupId, () => loadData());

    return {
      routeTypeName,
      transporters,
      isCurrentGeneral,
      current,
      printReport,
      canPrint,
      isOneMunicipality,
      isPPTransport,
      totalTransports,
      getAllOperators(operators?: Array<IOperator>) {
        if (operators) {
          return operators.map(element => element.name).join(', ');
        }
        return '';
      },
    };
  },
});
